.footer {
padding: 40px 0;
background: #DDE5FD;
    @include media("<=810px") {
    padding: 30px 0;
        
    }
}

.footer__inner {
   
    justify-content: space-between;
        width: 100%;
        flex-direction: column;
        align-items: center;
        display: flex;
     
    gap: 30px;
    position: relative;
    @include media("<=810px") {
     padding: 0;
    }
    @include media("<=640px") {
        
    }
}

.footer__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding: 20px 0;
    @include media("<=810px") {
       flex-wrap: wrap;
        gap: 20px;
    }
    &:first-of-type {
        border-bottom: 1px #12345B solid;
    }
}

.footer__column {
    display: flex;
    flex-direction: column;


        gap: 15px;

@include media("<=810px") {
    justify-content: flex-start;
   
    flex-direction: column;
    margin-left: 0;
}
        @include media("<=640px") {
               
            }
}


.footer__nav {
    display: flex;
    justify-content: flex-end;
    gap: 30px;
}

.footer__title {
    font-size: 20px;
    color: #12345B;
}

.footer__link {
    color: #12345B;
    font-size: 16px;
    text-decoration: none;
    @include media("<=810px") {
       
    }
    &:hover {
        text-decoration: underline;
    }
}



.copyright {
  color: #12345B;
    font-size: 14px;

    @include media("<=810px") {
        text-align: left;
        margin-left: 0;
        
    }
}

.footer__text {
    @include media("<=430px") {
        font-size: 14px;
    }
}

.footer__logo {
    color: #12345B;
    font-size: 40px;
        font-weight: 800;
     display: block;
        z-index: 21;
        text-decoration: none;
    @include media("<=810px") {
            font-size: 32px;
      width: 100%;
      padding-bottom: 20px;
        }
    
        @include media("<=430px") {
            font-size: 24px;
        }
}