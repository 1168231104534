.hero {
  padding: 180px 0 ;
  background-image: url(../images/hero-bcg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
//  z-index: 2;
  overflow-x: clip;
  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
    
    
  }
  @include media("<=430px") {
    
  }
  
}

.hero__inner {
  
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  @include media("<=1080px") {
    margin: 0 auto;
  }
  @include media("<=810px") {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
  @include media("<=630px") {
   
  }
  @include media("<=375px") {
  }
  @include media("<=320px") {
  }
  &-about {
      background-image: url(../images/about-bcg.png);
  }
  &-hotels {
      background-image: url(../images/hotels-bcg.png);
  }
}



.hero__content {
  flex: 0 1 100%;
  width: 100%;
  max-width: 645px;
  padding: 80px;
  background-color: #111;
  z-index: 24;
  line-height: 1.1;
  display: flex;
 
  flex-direction: column;
  
  color: #fff;
  justify-content: space-between;
  @include media("<=1440px") {
    
  }
  @include media("<=1080px") {
  
    
  }
  @include media("<=810px") {
   
    margin: 0 auto;
    line-height: 1.1;
    flex-direction: column;
    
   
  }
    @include media("<=500px") {
     width: 100%;
     padding: 40px 30px;
    }
}

.hero__title {

 max-width: 856px;
  font-size: 72px;
  font-weight: 800;
  width: 100%;
  opacity: 0;
 margin-bottom: 40px;
  transition: all 0.8s ease 0.2s;
  
    &._active {
      opacity: 1;
    }
        @include media("<=1440px") {
         
        }
  @include media("<=1280px") {
    
    
  }

  @include media("<=1080px") {
    font-size: 64px;
  }
  @include media("<=810px") {
   

    
  }
  @include media("<=500px") {
    font-size: 36px;
   
  }

  @include media("<=410px") {
    
  }
}

.hero__text {
  display: flex;
  flex-direction: column;
  gap: 30px;
  font-size: 16px;
  line-height: 1.2;
  color: #7270DD;
  margin-bottom: 3px;
  @include media("<=1280px") {
    
  }
}


.button { 
    text-decoration: none;
    border: none;
    width: 100%;
   max-width: 180px;

  display: flex;
    align-items: center;
    justify-content: center;
  color: #fff;
  font-size: 14px;
  font-weight: 800;
  text-decoration: none;
  text-align: center;
  padding: 14px;
  width: 100%;
  border: none;
border-radius: 2px;
  background: #7270DD;
  transition: all ease-in-out 0.3s;
  position: relative;
  z-index: 25;

  &:hover {

  background: #5553a7;
  }
    @include media("<=1440px") {
      
    }
        @include media("<=810px") {
         
        }
    @include media("<=430px") {
      
    }
        @include media("<=375px") {
          
        }
        &-w {
          background: #F2F5F7;
          color: #2A3036;
        }
      
      }


.hero__image {
  width: 100%;
  max-width: 444px;
  position: relative;
  z-index: 3;
  img {
   
    width: 100%;
  }
 
}





