.about {
    padding: 30px 0;
    @include media("<=500px") {
        
    }
    &-b {
        background-color: #2A3036;
    }
}

.about__inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 65px;
    line-height: 1.3;
    padding: 50px 0;
    
    @include media("<=900px") {
       flex-direction: column;
    }
@include media("<=500px") {
       
        align-items: center;
    }
   &-reverse {
   
    color: #fff;
    @include media("<=900px") {
    flex-direction: column-reverse;
    }
 
   }
}

.about__content {
    max-width: 454px;
    display: flex;
    flex-direction: column;
    gap: 30px;

  ul {
    list-style: disc;
    padding-left: 15px;
    display: flex;
    align-items: center;
        flex-direction: column;
        text-align: center;
  }
}


.about__image {
    max-width: 500px;
    position: relative;
    img {
        width: 100%;
    }
}

.about__pic {
    max-width: 370px;
    position: absolute;
    right: -50px;
    bottom: -50px;
    width: 70% !important;
    @include media("<=810px") {
        width: 50% !important;
        right: -20px;
    }
}

.about__icon {
    position: absolute;
    bottom: -20px;
    left: 150px;
    max-width: 96px;
}


.about__text {
display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 20px;
    line-height: 1.2;
   padding: 20px 0;
    span {
        font-size: 22px;
         font-weight: 800;
    }
}

.about__items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 670px;
}

.about__item {
    background-color: #111;
    padding: 40px;
    display: flex;
    gap: 40px;
    align-items: center;
    color: #fff;
}

.about__date {
    span {
        font-size: 24px;
            font-weight: 800;
    }
}




