.gallery {
    padding: 100px 0;
}

.section__head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    padding: 40px 0;
    &-light {
        border-radius: 4px;
            background: linear-gradient(0deg, #CD5C8D 0%, #494AC7 100%);
            filter: blur(0px);
            padding: 100px 20px;
            justify-content: space-around;
            color: #fff;
            margin: 40px 0;
    }
        @include media("<=810px") {
           flex-direction: column;
        }
}

.gallery__text {
    max-width: 470px;
    line-height: 1.3;
    p {
        margin-bottom: 30px;
    }
}

.gallery__inner {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.gallery__item {
   width: 100%;
   flex: 0 1 48.5%;
   height: 560px;
   position: relative;
   display: flex;
   align-items: flex-end;
   padding: 50px;
@include media("<=810px") {
    flex: 0 1 100%;
    
}
@include media("<=550px") {
   height: 500px;
   padding: 40px 30px;
}
   &-big {
    flex: 0 1 100%;
   }
   img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
   }
}

.gallery__title {
    padding: 40px;
    background-color: #111;
    max-width: 470px;
    width: 100%;
    position: relative;
    z-index: 3;
    @include media("<=550px") {
      padding: 30px 20px;
    }
    span {
        display: block;
        font-weight: 800;
        font-size: 22px;
        padding-bottom: 15px;
    }
}