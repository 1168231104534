.contacts {
   padding: 60px 0;
  .section__title {
    color: #fff;
  }
}

.contacts__inner  {
    display: flex;
   background-color: #554CC2;
   gap: 20px;
   text-align: center;
   padding: 40px 20px;
        align-items: center;
        justify-content: center;
   
    @include media("<=810px") {
        flex-direction: column;
        
    }
}

.contacts__content {
    display: flex;
    flex-direction: column;
    align-items: center;
        justify-content: center;
        max-width: 620px;
        width: 100%;
        gap: 30px;
}

.contacts__title {
    font-size: 20px;
    font-weight: 750;
    text-transform: uppercase;
    text-align: center;
}



.form__title {
    font-size: 32px;
    text-align: center;
}

.contacts__form {
    width: 100%;

}

.form {
    display: flex;
    align-items: center;
   flex-direction: column;
    padding-top: 40px;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
   
    @include media("<=630px") {
        flex-direction: column;
    }
    
}

.form__input {
    width: 100%;
    
    input {
     
        width: 100%;
        font-size: 14px;
        border: none;
        color: #000;
        padding:  19px 30px;
border-radius: 20px;
    background: #FFF;
font-family: "Inter", sans-serif;
        @include media("<=430px") {}
    }
    textarea {
        resize: none;
     
        width: 100%;
            font-size: 12px;

            color: #000;
            padding: 19px 30px;
        border-radius: 20px;
            background: #FFF;
            font-family: "Open Sans", sans-serif;
            border: none;
    }
    
        
}

.contacts__text {
    padding-top: 40px;
    text-align: center;
    line-height: 1.3;
}

.contacts__image {
    max-width: 620px;
    width: 100%;
    img {
        width: 100%;
    }
}







