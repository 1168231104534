body {
  margin: 0;
font-family: "Assistant", sans-serif;
background: #111;
  color: #fff;
  position: relative;
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.container {
  width: 100%;
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
  
}

.section__title {
   max-width: 570px;
    font-size: 56px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    width: 100%;
    line-height: 1.1;
    @include media("<=810px") {
       font-size: 40px;
    }
        @include media("<=430px") {
          font-size: 28px;
        }
        span {
          color: #7270DD;
          display: block;
          font-weight: 400;
          font-size: 16px;
        }
}

.section__link {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  text-decoration: none;
  font-size: 20px;
}

.section__subtitle {
  padding: 20px 0;
  font-size: 22px;
font-weight: 800;
 line-height: 1.3;
display: flex; 
flex-direction: column;
gap: 15px;

}

.section__text {
  padding: 30px 0 50px;
  max-width: 996px;
}

.section__image {
  margin-top: 30px;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  @include media("<=810px") {
    height: 150px;
    position: relative;
  }
  img {
    width: 100%;
    display: block;
    @include media("<=810px") {
      height: 100%;
      position: absolute;
      object-fit: cover;
    }
  }
}

.wrapper {
  overflow: hidden;
}

.light {
  padding: 40px 0;
  background-color: #DDE5FD;
  color: #12345B;
  overflow: hidden;
  .section__link {
    color: #12345B;
  }
}

.blue {
  overflow: hidden;
  padding: 60px 0;
    background-color: #12345B;
}

//BURGER BUTTON//
.burger__button {
  display: none;

  @include media("<=840px") {
    display: block;
    position: fixed;
    top: 28px;
    right: 40px;
    z-index: 200;
  }

  @include media("<=430px") {
    top: 26px;
    right: 20px;
  }

  @include media("<=840px") {
    &.active {
      position: fixed;
      top: 32px;
      right: 40px;
      z-index: 200;
    }

    @include media("<=430px") {
      &.active {
        top: 32px;
        right: 20px;
      }
    }
  }

  .line {
    height: 4px;
    width: 50px;
    border-radius: 4px;
    margin-bottom: 6px;
    background-color: #fff;
    transition: all 200ms ease-in-out;
    opacity: 1;

    @include media("<=736px") {
      height: 4px;
      width: 48px;
    }
  }

  &.active {
    .line {
      background-color: #fff;

      &.one {
        transform: rotate(45deg) ;
      }

      &.two {
        transform: rotate(-45deg)  translateY(-7px) translateX(7px);

        @include media("<=736px") {
          transform: rotate(-45deg)  translateY(-7px) translateX(7px);
        }
      }

      &.three {
        opacity: 0;
      }
    }
  }
}

.page {
  padding: 120px 0 80px;
}

.page__inner {
  padding-top: 60px;
  line-height: 1.2;
  p {
    padding-bottom: 20px;
  }
  strong {
    font-weight: 600;
    display: block;
    padding: 10px 0;
  }
}

.thank__inner {
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 50px;
  font-size: 20px;
  font-weight: 500;
  color: #F2F5F7;
}

.consent {
  border-radius: 3px;
  background: #F4F4F4;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  box-shadow: 0 4px 8px #464646;
  max-width: 730px;
  width: 95%;
  position: fixed;
  left: 10%;
  bottom: 50px;
  transform: translateX(-10%);
  z-index: 28;

  @include media("<=810px") {
    left: 50%;
    transform: translateX(-50%);
  }

  img {
    max-width: 40px;
  }
}

.consent__text {
  font-size: 14px;
  color: #131313;

  span {
    display: block;
    font-size: 30px;
    text-transform: uppercase;
  }
}

.consent__buttons {
  display: flex;

  gap: 30px;
  align-items: center;
  width: 100%;

  @include media("<=810px") {
    justify-content: center;
  }

  @include media("<=600px") {
    flex-direction: column;
  }
}