.reviews {
  padding: 0 0 80px;

  @include media("<=550px") {}

 
}

.reviews__inner {
  display: flex;
    justify-content: space-between;
    
   
    padding-top: 60px;
   
    gap: 30px;
    @include media("<=780px") {
      flex-direction: column;
    }
}

.reviews__item {
  flex: 0 1 20%;
  display: flex;
    align-items: center;
    flex-direction: column;
  font-size: 12px;
  line-height: 1.1;
  text-align: center;
  color: #fff;
  @include media("<=780px") {
    flex: 0 1 100%;
  }
}

.reviews__text {
  line-height: 1.5;
}

.reviews__title {
  font-weight: 500;
  font-size: 24px;
  gap: 8px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.reviews__image {
  
  display: block;
  margin-bottom: 30px;
}