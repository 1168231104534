.header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 28px 0;

  @include media("<=1080px") {
    
  }
  @include media("<=840px") {
    padding: 22px 0;
  }
  @include media("<=430px") {
    
  }
 &-page {
  background: #262F24;
 }
}

.header__inner {

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  
  gap: 30px;
  z-index: 30;
  @include media("<=1080px") {
  
      margin: 0 auto;
    }
  @include media("<=840px") {
    justify-content: flex-start;
   background-color: transparent;
   padding: 0;
  }
}

.header__nav {
  
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 40px;
  @include media("<=1080px") {
    
  }

  @include media("<=840px") {
    display: none;
    max-width: 100%;

    &.active {
      display: flex;
      position: fixed;
      height: 100%;
      max-height: 700px;
      width: 100%;
      max-width: none;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: #111;
      z-index: 20;
      margin-right: 0;
      margin-left: 0;
      padding: 90px 20px 20px;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: flex-start;
      opacity: 1;

      @include media("<=430px") {
        padding-bottom: 0;

      }

      .header__link {
        padding: 15px;

        @include media("<=430px") {
         
        }

        @include media("<=350px") {
          
        }
      }
    }
  }
}

.header__link {
 
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  color: #fff;
  transition: all 0.3s ease;
  position: relative;

  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
   
    background-color: transparent;
    font-size: 16px;
  }

  &:hover {
    
  }
}

.header__link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.1em;
  background: #fff;
  bottom: -6px;
  left: 0;
  transform: scale(0, 1);
  transition: transform 0.3s ease;
}

.header__link:hover::after {
  transform: scale(1, 1);
}

.logo {
  display: block;
  color: #fff;
  font-size: 40px;
  font-weight: 800;

  z-index: 21;
  text-decoration: none;
  @include media("<=1440px") {
    
  }
    @include media("<=1080px") {
      
    }
  @include media("<=810px") {
    font-size: 32px;
  
  }
    @include media("<=430px") {
       font-size: 24px;
    }
  img {
    width: 100%;
    display: block;
  }
  
}

.header__button {
  padding: 14px;
  font-weight: 800;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.30);
  max-width: 185px;
  width: 100%;
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media("<=840px") {
    display: none;
  }
}

