.features {
  position: relative;
  padding: 60px 0;
  overflow: hidden;
  
  @include media("<=1440px") {

  }
  &-bcg {
    background-image: url(../images/f-bcg.png);
    background-position: center;
    background-size: cover;
    color: #fff;
  }
  &-d {
      background-color: #2A3036;
      color: #fff;
  }
}

.features__inner {
  display: flex;
    justify-content: space-around;
    padding: 80px 0;
    position: relative;
    z-index: 3;
    gap: 40px;
    @include media("<=1440px") {
      

    }
        @include media("<=1080px") {
          
        }
                @include media("<=810px") {
                   flex-wrap: wrap;
                }
        @include media("<=700px") {
            flex-direction: column;
            align-items: center;
        }
      
}



.features__title {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 500;
}

.features__item {
  font-size: 16px;
  span {
    font-size: 20px;
    text-transform: uppercase;
    display: block;
    padding-bottom: 20px;
  }
}

.features__image {
  width: 100%;
  margin: 30px 0;
  img {
    width: 100%;
  }
}

.section__item {
  max-width: 333px;
  flex: 0 1 31%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  font-size: 16px;
  line-height: 1.1;
  transition: all 0.8s ease 0.2s;
  @include media("<=1440px") {
    
  }
  @include media("<=1080px") {
 
  }
  
  @include media("<=810px") {
    
  }
  @include media("<=500px") {
     flex: 0 1 100%;
     width: 100%;
  }
 @include media("<=360px") {
   

 }
  ul {
    list-style: disc;
    padding-left: 15px;
    line-height: 1.4;
  }
  &-r {
    background-color: #111;
    padding: 30px 15px;
    color: #fff;
  }
}

.item__image {
  position: relative;
  @include media("<=550px") {
     margin: 0 auto;
  }
 img {
  width: 100%;
 }
}

.item__big {
  text-transform: uppercase;
  margin-bottom: 30px;
  font-size: 28px;
}

.item__title {
  line-height: 1.3;
  font-weight: 800;
  font-size: 24px;
  

@include media("<=1440px") {
  
}
  @include media("<=1280px") {
   
  }

  @include media("<=450px") {
    font-size: 20px;
  }
    @include media("<=380px") {
      
    }
                span {
                  color: #7270DD;
                  display: block;
                  font-weight: 400;
                  font-size: 16px;
                }
}

.item__text {

  
@include media("<=1080px") {

  }
  @include media("<=810px") {
   

  }

  @include media("<=430px") {
   
  }
}

.item__num {
  border-radius: 100px;
    background: linear-gradient(0deg, #CD5C8D 0%, #494AC7 100%);
    width: 64px;
    height: 64px;
    display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 800;
      font-size: 24px;
      position: absolute;
      left: -10%;
      top: -8%;
}

