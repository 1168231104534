.info {

    overflow: hidden;
        padding: 60px 0;
       
        &-bcg {
        background-image: url(../images/info-bcg.png);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            color: #fff;
            padding: 100px 0;
        }
}

.info__inner {
display: flex;
    
    gap: 30px;

    justify-content: space-between;
    
  

@include media("<=500px") {
        flex-direction: column;
        align-items: center;
    }
}

.info__content {
    padding-top: 50px;
    margin-left: auto;
    max-width: 470px;
}

.info__stats {
    display: flex;
    padding-top: 30px;
    gap: 30px;
    justify-content: space-between;
    span {
        font-weight: 800;
        font-size: 56px;
        display: block;
    }
}

.info__item {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 1.3;
    flex: 0 1 100%;
    ul {
        list-style: disc;
        padding-left: 15px;
    }
}

.info__title {
    font-size: 20px;
    text-transform: uppercase;
}

.info__text {
    width: 100%;
    padding: 15px 0;
    span {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 22px;
        display: block;
        padding-bottom: 18px;
    }
    p {
        text-align: center;
    }
}

.info__price {
    font-weight: 700;
    margin-bottom: 30px;
}

.info__image {
    max-width: 383px;
    img {
        width: 100%;
    }
}


.sector {
    padding: 80px 0;
}

.sector__inner {
    padding-top: 60px;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    @include media("<=500px") {
        flex-direction: column;
        align-items: center;
    }
}

.sector__item {
    border: 4px solid #214399;
    padding: 16px;
    color: #214399;
    font-size: 24px;
    text-align: center;
    font-weight: 700;
    @include media("<=500px") {
        font-size: 20px;
        width: 100%;
    }
}